/* global Office */

import * as React from 'react';
import Authentication from './auth/Authentication';
import {ProgressIndicator} from 'office-ui-fabric-react';
import {CurrentEmailContext} from "./CurrentEmailContext";
import CurrentComposeEmail from "./compose/CurrentComposeEmail";
import CurrentReadEmail from "./read/CurrentReadEmail";
import {FylerLogger} from "@verlata/fyler-office-common";

export class App extends React.Component {

    state = {
        currentEmail: null,
        currentEmailTimestamp: null
    };

    constructor(props, context) {
        super(props, context);

        this.initOffice();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOfficeInitialized && !prevProps.isOfficeInitialized) {
            this.initOffice();
        }
    }

    render() {
        if (this.props.isOfficeInitialized && !!this.state.currentEmail) {
            return (
                <CurrentEmailContext.Provider value={this.state.currentEmail}>
                    <Authentication key={`app-for-${this.state.currentEmailTimestamp}`}/>
                </CurrentEmailContext.Provider>
            );
        }
        else {
            return (<ProgressIndicator/>);
        }
    }

    initOffice() {
        if (this.props.isOfficeInitialized) {
            FylerLogger.debug('Initialising office listeners');

            this.updateCurrentEmail();

            Office.context.mailbox.masterCategories.addAsync([
                {"displayName": "Saved in Fyler",
                  "color": Office.MailboxEnums.CategoryColor.Preset0}], function (asyncResult) {
                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                    console.log("Successfully added categories to master list");
                } else {
                    console.log("masterCategories.addAsync call failed with error: " + asyncResult.error.message);
                }
            });

            Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, () => {
                if (!!Office.context.mailbox.item) {
                    FylerLogger.info(`Item changed to: ${Office.context.mailbox.item.internetMessageId}`);

                    this.updateCurrentEmail();
                }
            });
        }
    }

    updateCurrentEmail() {
        this.setState({
            currentEmail: this.newCurrentEmail(),
            currentEmailTimestamp: new Date().getTime()
        });
    }

    newCurrentEmail() {
        if (Office.context.mailbox.item.addFileAttachmentAsync !== undefined) {
            return new CurrentComposeEmail(Office.context.mailbox.item, Office.context.mailbox);
        }
        else {
            return new CurrentReadEmail(Office.context.mailbox.item, Office.context.mailbox);
        }
    }
}

export default App;
