import * as React from 'react';
import {DetailsList, DetailsListLayoutMode, SelectionMode} from 'office-ui-fabric-react/lib/DetailsList';
import {IconButton} from 'office-ui-fabric-react';
import {MatterLink} from "@verlata/fyler-office-common";
import {FylerLogger} from "@verlata/fyler-office-common";

export default class ReadingMatterSearchResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const columns = [
            {
                key: 'saveToMatter',
                name: '',
                minWidth: 32,
                maxWidth: 32,
                isResizable: true,
                onRender: (matter) => {
                    return (
                        <IconButton iconProps={{ iconName: 'Save' }}
                                    title='Save to this location'
                                    ariaLabel='Save to this location'
                                    onClick={() => this.props.moveToMatterCallback(matter)}/>
                    );
                }
            },
            {
                key: 'description',
                name: this.props.columnTitle || '',
                minWidth: 128,
                isResizable: true,
                onRender: (matter) => {
                    let parentDescription = null;
                    if (!!matter['parent']) {
                        parentDescription = matter['parent']['description'];
                    }
                    return <MatterLink id={matter['id']}
                                       description={matter['description']}
                                       parentDescription={parentDescription}
                                       onClick={() => this.props.moveToMatterCallback(matter)}
                                       size={'small'}/>;
                }
            },
            {
                key: 'uploadToMatter',
                name: '',
                minWidth: 32,
                maxWidth: 32,
                isResizable: true,
                onRender: (matter) => {
                    return (
                        <IconButton iconProps={{ iconName: 'Upload' }}
                                    title='Upload to this location'
                                    ariaLabel='Upload to this location'
                                    onClick={() => this.props.uploadToMatterCallback(matter)}/>
                    );
                }
            },
        ];

        FylerLogger.info(`Loading results for: ${JSON.stringify(this.props.results)}`);

        return (
            <DetailsList
                items={this.props.results}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={!!this.props.columnTitle}
                selectionMode={SelectionMode.none}
                {...(this.props.unitTest && {
                    skipViewportMeasures: true,
                    onShouldVirtualize: () => {return false}
                })}
            />
        );
    }
}
