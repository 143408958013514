import * as React from 'react';
import {
    CheckboxVisibility,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    Selection,
    SelectionMode
} from 'office-ui-fabric-react/lib/DetailsList';
import {MatterLink} from "@verlata/fyler-office-common";
import {DetailsRowFields} from "office-ui-fabric-react/lib/components/DetailsList/DetailsRowFields";

export default class ComposingMatterSearchResult extends React.Component {

    constructor(props) {
        super(props);

        this.selection = new Selection({
            selectionMode: SelectionMode.single,
            onSelectionChanged: this.selectionChanged
        });
        this.selection.setItems(props.results);
        this.selection.setIndexSelected(0, true, false);
    }

    state = {
        selection: null,
    };

    render() {
        return (
            <DetailsList
                items={this.props.results}
                checkboxVisibility={CheckboxVisibility.always}
                columns={[
                    {
                        key: 'description',
                        name: '',
                        minWidth: 128,
                        isResizable: true,
                        onRender: (matter, index) => {
                            let parentDescription = null;
                            if (!!matter['parent']) {
                                parentDescription = matter['parent']['description'];
                            }
                            return <MatterLink id={matter['id']}
                                               description={matter['description']}
                                               parentDescription={parentDescription}
                                               onClick={() => this.selection.toggleIndexSelected(index)}
                                               size={'small'}/>;
                        }
                    }
                ]}
                onRenderRow={this.renderRow}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={false}
                selection={this.selection}
                {...(this.props.unitTest && {
                    skipViewportMeasures: true,
                    onShouldVirtualize: () => {return false}
                })}
            />
        );
    }

    renderRow(props) {
        return <DetailsRow rowFieldsAs={ComposingMatterSearchResult.renderRowFields} {...props} />;
    }

    static renderRowFields(props) {
        return <span data-selection-disabled={true}>
            <DetailsRowFields {...props} />
        </span>;
    }

    selectionChanged = () => {
        if (this.selection.getSelectedCount() > 0) {
            this.props.saveToMatterCallback(
                this.props.results[
                    this.selection.getSelectedIndices()[0]]);
        }
        else {
            this.props.saveToMatterCallback(null);
        }
    }
}
