export default class CurrentEmail {

    async officePromise(officeAsyncCall, successfulResultCall) {
        return await new Promise((resolve) => {
            officeAsyncCall((result) => {
                resolve(successfulResultCall(!!result ? result.value : null));
            });
        });
    }

}
