import * as React from 'react';
import {DetailsList, DetailsListLayoutMode, Selection, SelectionMode, TextField} from "office-ui-fabric-react";

export default class SaveEmailItems extends React.Component {

    state = {
        items: null
    };

    constructor(props, context) {
        super(props, context);

        this.EMAIL_ITEM_ID = '-1';

        this.attachments = this.props.currentEmail.attachments().map((nextAttachment) => {
            return {
                id: nextAttachment.id,
                name: nextAttachment.name,
                contentType: nextAttachment.contentType
            };
        });
        this.state.items = [
            {
                id: this.EMAIL_ITEM_ID,
                name: null
            }
        ].concat(this.attachments.map((nextAttachment) => {
            return {
                id: nextAttachment['id'],
                name: nextAttachment['name'],
                attachment: nextAttachment
            };
        }));

        this.selection = new Selection({
            selectionMode: SelectionMode.multiple,
            onSelectionChanged: this.selectionChanged
        });
        this.selection.setItems(this.state.items);
        this.selection.setIndexSelected(0, true, false);
    }

    componentDidMount() {
        this.props.shouldSaveEmailCallback(true);
        this.props.shouldSaveAttachmentCallback([], this.attachments);
    }

    render() {
        return <div style={{marginTop: '1rem'}}>
            <DetailsList
                compact={true}
                items={this.state.items}
                columns={[
                    {
                        key: 'name',
                        name: 'What to file',
                        minWidth: 196,
                        isResizable: false,
                        onRender: (item) => {
                            if (this.isEmailItem(item)) {
                                return <span>Email with attachments</span>;
                            }
                            else {
                                return <TextField className={'attachment-name'}
                                                  underlined
                                                  {...(item['name'].length === 0 && {
                                                      errorMessage: 'Required'
                                                  })}
                                                  style={{fontSize: '12px'}}
                                                  styles={{root: {width: '100%'}}}
                                                  value={item['name']}
                                                  onChange={(event) => {
                                    this.attachmentNameChanged(item.id, event.target['value']);
                                }}/>;
                            }
                        }
                    }
                ]}
                layoutMode={DetailsListLayoutMode.justified}
                selection={this.selection}
                {...(this.props.unitTest && {
                    skipViewportMeasures: true,
                    onShouldVirtualize: () => {return false}
                })}/>
        </div>;
    }

    attachmentNameChanged = (id, name) => {
        const item = this.state.items.find((nextItem) => {
            return nextItem['id'] === id;
        });

        if (!!item) {
            item['attachment']['name'] = name;
            item['name'] = name;
            this.setState({items: this.state.items}, () => {
                this.props.shouldSaveAttachmentCallback([], [item['attachment']]);
                if (this.selection.getSelectedIndices().includes(this.attachments.indexOf(item['attachment']) + 1)) {
                    this.props.shouldSaveAttachmentCallback([item['attachment']], []);
                }
            });
        }
    };

    isEmailItem = (item) => {
        return item.id === this.EMAIL_ITEM_ID;
    };

    selectionChanged = () => {
        const selectedIndices = this.selection.getSelectedIndices();
        this.props.shouldSaveEmailCallback(selectedIndices.includes(0));

        this.props.shouldSaveAttachmentCallback(
            this.attachments.filter((_, index) => selectedIndices.includes(index + 1)),
            this.attachments.filter((_, index) => !selectedIndices.includes(index + 1))
        );
    };
}
