import * as React from 'react';
import {IconButton, Spinner} from "office-ui-fabric-react";
import {DocumentLink} from "@verlata/fyler-office-common";
import FylerOutlookUtils from "../../FylerOutlookUtils";

export default class AttachableDocumentLink extends React.Component {

    state = {
        attaching: false,
    }

    render() {
        if (this.state.attaching) {
            return <Spinner/>;
        }
        else {
            const highlight = this.props.document.isEmail() &&
            this.props.currentEmail.composing() ? false :
                this.props.currentEmail.is(this.props.document.emailId());
            return <DocumentLink apiClient={this.props.apiClient}
                                 document={this.props.document}
                                 highlight={highlight}
                                 download={this.download}
                                 previewDownloadLabel={'Attach'}
                                 previewDownloadCallback={this.attach}
                                 extraButtons={[
                                     <IconButton iconProps={{iconName: 'Attach'}}
                                                 key={'attachButton'}
                                                 title='Attach to this email'
                                                 ariaLabel='Attach to this email'
                                                 onClick={() => this.attach()}/>
                                 ]}/>;
        }
    }

    download = () => {
        this.setState({attaching: true});

        FylerOutlookUtils.download(this.props.apiClient, this.props.document).then(() => {
            this.setState({attaching: false});
        });
    }

    attach = () => {
        this.setState({attaching: true});

        FylerOutlookUtils.attach(this.props.apiClient, this.props.currentEmail, this.props.document.asObject())
            .then(() => {
                this.setState({attaching: false});
            });
    }

}
