import * as React from 'react';
import {
    Text, MessageBar, MessageBarType, Icon, Spinner
} from 'office-ui-fabric-react';
import { MatterLink, FylerUtils } from '@verlata/fyler-office-common';

export default class EmailTitle extends React.Component {

    state = {
        subject: null,
        loading: true
    };

    componentDidMount() {
        this.props.email.subject().then((subject) => {
            this.setState({
                loading: false,
                subject: subject
            });
        })
    }

    render() {
        if (this.state.loading) {
            return <Spinner/>;
        }
        else {
            return (
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={true}>
                    <div style={{marginBottom: '0.5rem'}} title={this.state.subject}>
                        <Text variant={'large'}>
                            {FylerUtils.abbreviate(this.state.subject, 30)}
                        </Text>
                    </div>
                    <div className={'center-vertically'}>
                        <Icon iconName={'FabricFolder'} className={'icon-for-text'}/>
                        <MatterLink id={this.props.matter['id']} description={this.props.matter['description']} size={'medium'}/>
                    </div>
                </MessageBar>
            );
        }
    }
}
