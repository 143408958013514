import * as React from 'react';
import {
    MessageBar,
    MessageBarType,
    Pivot, PivotItem,
    PivotLinkSize,
    ProgressIndicator,
    Text
} from 'office-ui-fabric-react';
import SaveEmailSearch from "../matter/SaveEmailSearch";
import MailDocumentSearch from "../documents/MailDocumentSearch";
import {MatterLink} from "@verlata/fyler-office-common";

export default class ComposeHome extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        unitTest: false
    };

    state = {
        matter: null,
        results: null,
        working: false,
    };

    render() {
        if (this.state.working || this.props.currentEmail == null) {
            return (<ProgressIndicator/>);
        }
        else {
            return (<Pivot linkSize={PivotLinkSize.normal}>
                <PivotItem headerText={'File'}>
                    {this.renderFiling()}
                </PivotItem>
                <PivotItem itemIcon={'Attach'}>
                    <MailDocumentSearch apiClient={this.props.apiClient} currentEmail={this.props.currentEmail}/>
                </PivotItem>
            </Pivot>);
        }
    }

    renderAlreadyFiled() {
        const willSave = !!this.state.matter;
        return (
            <MessageBar
                messageBarType={willSave ? MessageBarType.success : MessageBarType.severeWarning}
                isMultiline={true}>
                <div>
                    <Text variant={'large'}>
                        {willSave ? 'Ready to file' : 'Will not file'}
                    </Text>
                </div>
                {willSave ?
                    <MatterLink id={this.state.matter['id']}
                                description={this.state.matter['description']}
                                size={'large'}/> :
                    <em>None</em>}
            </MessageBar>
        );
    }

    renderFiling() {
        return (<div>
            {this.renderAlreadyFiled()}
            {this.renderSaveEmailSearch()}
        </div>);
    }

    renderSaveEmailSearch() {
        return <SaveEmailSearch currentEmail={this.props.currentEmail}
                                apiClient={this.props.apiClient}
                                matter={this.state.matter}
                                saveCallback={this.saveEmailToMatter}
                                unitTest={this.props.unitTest}
                                asyncCallMade={this.props.asyncCallMade}/>;
    }

    saveEmailToMatter = (matter) => {
        const updateStateCallback = () => {
            if (this.state.matter !== matter) {
                this.setState({matter: matter});
            }
        };

        if (!!matter) {
            this.props.currentEmail.save(matter['id'], this.props.username, updateStateCallback);
        }
        else {
            this.props.currentEmail.unsave(updateStateCallback);
        }
    }
}
