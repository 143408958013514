import * as React from 'react';
import {FylerLogger} from "@verlata/fyler-office-common";
import Dropzone from "react-dropzone";
import { FileUploader } from '@verlata/fyler-office-common';
import {Icon, ProgressIndicator} from 'office-ui-fabric-react'

export default class DocumentUpload extends React.Component {

    state = {
        working: false
    };

    render() {
        if (this.state.working) {
            return (<ProgressIndicator/>);
        }
        return (<Dropzone onDrop={this.uploadFiles}
            className={'dropZone'}
            multiple={true}
            maxSize={104857600}>
            {({getRootProps, getInputProps}) => (
                <section style={{padding: '0.5rem',
                                width: '96%',
                                height: '100%',
                                backgroundColor: '#F0F0F0',
                                border: 'dashed',
                                borderColor: '#C8C8C8',
                                cursor: 'pointer',
                                boxSizing: 'border-box'
                                }}>
                    <div {...getRootProps({className: 'fullHeight vertically-centered'})}>
                        <div className='dropzoneTextStyle'>
                            <p className='dropzoneParagraph'>
                                    Drag and drop files to upload to <b>{this.props.matter.description}</b>
                            </p>
                            <Icon iconName={'CloudUpload'} style={{fontSize: '40px'}}/>
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>);
    }

    uploadFiles = (files) => {
        this.setState({working: true}, async () => {
            let uploadErrors = 0;
            for (let i = 0; i < files.length; ++i) {
                let uploader = new FileUploader(this.props.apiClient);
                let username = this.props.username;
                let matterId = this.props.matter.id;

                if (files[i].name.endsWith('.msg')) {
                    await uploader.parseAndCreateEmail(files[i], username, matterId).catch((error) => {
                        FylerLogger.error(error);
                        uploadErrors = uploadErrors + 1;
                    });
                }
                else {
                    await uploader.create(files[i], matterId, username).catch((error) => {
                        FylerLogger.error(error);
                        uploadErrors = uploadErrors + 1;
                    });
                }
            }
            this.props.finished(uploadErrors);
        });
    }
}