import * as React from 'react';
import {Spinner} from "office-ui-fabric-react";
import {DocumentLink} from "@verlata/fyler-office-common";
import FylerOutlookUtils from "../../FylerOutlookUtils";

export default class DownloadableDocumentLink extends React.Component {

    static defaultProps = {
        extraButtons: []
    };

    state = {
        downloading: false,
    }

    render() {
        if (this.state.downloading) {
            return <Spinner/>;
        }
        else {
            const highlight = this.props.document.isEmail() &&
                this.props.currentEmail.composing() ? false :
                this.props.currentEmail.is(this.props.document.emailId());
            return <DocumentLink apiClient={this.props.apiClient}
                                 document={this.props.document}
                                 highlight={highlight}
                                 download={this.download}
                                 extraButtons={this.props.extraButtons}/>;
        }
    }

    download = () => {
        this.setState({downloading: true});

        FylerOutlookUtils.download(this.props.apiClient, this.props.document).then(() => {
            this.setState({downloading: false});
        });
    }

}
