import * as React from 'react';
import {
    MessageBar,
    MessageBarType, Pivot, PivotItem, PivotLinkSize,
    ProgressIndicator,
} from 'office-ui-fabric-react';
import gql from 'graphql-tag';
import MatterDocuments from "../matter/MatterDocuments";
import SaveEmailSearch from "../matter/SaveEmailSearch";
import MailDocumentSearch from "../documents/MailDocumentSearch";
import DownloadableFylerDocument from "../DownloadableFylerDocument";
import MatterEmails from "../matter/MatterEmails";
import {MatterFolders, MatterLink, FylerLogger} from "@verlata/fyler-office-common";
import EmailTitle from './EmailTitle';
import SaveEmailItems from "../matter/SaveEmailItems";
import DocumentUpload from "./DocumentUpload";

export default class ReadHome extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        unitTest: false
    };

    state = {
        matter: null,
        matterId: null,
        emailMatter: null,
        results: null,
        working: true,
        errorMessage: null,
        successMessage: null,
        saveAttachments: [],
        saveEmail: true,
        dragAndDrop: false,
        unitTest: ""
    };

    componentDidUpdate(prevProps) {
        if (this.props.currentEmail.emailId() !== prevProps.currentEmail.emailId()) {
            this.loadEmail();
        }
    }

    componentDidMount() {
        this.loadEmail();
    }

    render() {
        if (this.state.working) {
            return (<ProgressIndicator/>);
        }
        else if (this.state.dragAndDrop) {
            return <DocumentUpload apiClient={this.props.apiClient}
                                   matter={this.state.matter}
                                   username={this.props.username}
                                   finished={this.finishedUpload} />
        }
        else {
            if (!!this.state.matter) {
                this.props.addFilingIndicator();
                return (<div>
                    {!!this.state.errorMessage && this.renderError()}
                    {this.renderMatter()}
                    <Pivot linkSize={PivotLinkSize.large}>
                        <PivotItem itemIcon={'Mail'} headerButtonProps={{title: 'Emails'}}>
                            <MatterEmails apiClient={this.props.apiClient} currentEmail={this.props.currentEmail} emails={this.state.matter['emails']} unitTest={this.props.unitTest}/>
                        </PivotItem>
                        <PivotItem itemIcon={'TextDocument'} headerButtonProps={{title: 'Documents'}}>
                            <MatterDocuments apiClient={this.props.apiClient} currentEmail={this.props.currentEmail} documents={this.state.matter['documents']} unitTest={this.props.unitTest}/>
                        </PivotItem>
                        {
                            (!!this.state.matter['folders']['parent'] || this.state.matter['folders']['children'].length > 0) &&
                                <PivotItem itemIcon={'FabricFolder'} headerButtonProps={{title: 'Folders'}}>
                                    <MatterFolders parent={this.state.matter['folders']['parent']} children={this.state.matter['folders']['children']} folderClicked={this.matterClicked}/>
                                </PivotItem>
                        }
                        <PivotItem itemIcon={'Search'} headerButtonProps={{title: 'Search for documents and emails'}}>
                            <MailDocumentSearch apiClient={this.props.apiClient} currentEmail={this.props.currentEmail} unitTest={this.props.unitTest}/>
                        </PivotItem>
                    </Pivot>
                </div>);
            }
            else {
                this.props.removeFilingIndicator();
                return (<div>
                    {!!this.state.errorMessage && this.renderError()}
                    {!!this.state.successMessage && this.renderSuccess()}
                    <Pivot linkSize={PivotLinkSize.large}>
                        <PivotItem headerText={'File'}>
                            {this.renderSaveEmailSearch()}
                        </PivotItem>
                        <PivotItem itemIcon={'Search'} headerButtonProps={{title: 'Search for documents and emails'}}>
                            <MailDocumentSearch apiClient={this.props.apiClient} currentEmail={this.props.currentEmail} unitTest={this.props.unitTest}/>
                        </PivotItem>
                    </Pivot>
                </div>);
            }
        }
    }

    finishedUpload = (errorCount) => {
        this.setState({
            successMessage: (errorCount === 0 ? "Succesfully uploaded files." : null), 
            dragAndDrop: false,
            matter: null,
            errorMessage: (errorCount === 0 ? null : `Failed to upload ${errorCount} files`)
        });
    }

    loadEmail = () => {
        this.setState({
            working: true,
            matterId: null,
            matter: null,
            emailMatter: null
        });

        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {email(email_id: "${this.props.currentEmail.emailId()}") { 
                    id
                    folder_id
                }}`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                let matterId;
                if (!!results.data['email']) {
                    matterId = results.data['email']['folder_id'];
                }

                this.setState({
                    working: !!matterId,
                    matterId: matterId
                }, () => {
                    if (!!matterId) {
                        this.loadMatter();
                    }
                });
            }).catch((e) => {
                FylerLogger.error(`Failed to load email: ${JSON.stringify(e)}`);
                this.setState({
                    errorMessage: 'Failed to contact server. Check your internet connection and try again.',
                    working: false
                })
            })
        );
    };

    loadMatter = () => {
        this.setState({
            working: true,
            matter: null,
        });
        const matterId = this.state.matterId;
        this.props.apiClient.query({
            query: gql(`query {
                folder(folder_id: "${matterId}") {
                    id description
                    parent { id description }
                    children { id description }                 
                }
                docs(folder_id: "${matterId}") { id folder_id file_name user_name snippet metadata { name value } }
            }`),
            fetchPolicy: 'no-cache'
        }).then((results) => {
            let contents = results.data['docs'].map((nextDoc) => {
                return new DownloadableFylerDocument(nextDoc, this.props.unitTest);
            }).sort((document0, document1) => {
                return document1.timestamp() - document0.timestamp();
            });

            const matter = {
                id: matterId,
                description: results.data['folder']['description'],
                folders: {
                    parent: results.data['folder']['parent'],
                    children: results.data['folder']['children'],
                },
                documents: contents.filter((nextDocument) => {
                    return !nextDocument.isEmail();
                }),
                emails: contents.filter((nextDocument) => {
                    return nextDocument.isEmail();
                })
            };
            this.setState({
                working: false,
                matter: matter,
                emailMatter: this.state.emailMatter || matter
            });
        }).catch((e) => {
            FylerLogger.error(`Failed to load folder: ${JSON.stringify(e)}`);
            this.setState({
                errorMessage: 'Failed to contact server. Check your internet connection and try again.',
                working: false
            })
        });
    };

    matterClicked = (matter) => {
        this.setState({matterId: matter['id']}, this.loadMatter);
    };

    renderError() {
        return <MessageBar messageBarType={MessageBarType.error}
                           dismissButtonAriaLabel='Close'>
            <p>{this.state.errorMessage}</p>
        </MessageBar>;
    };

    renderSuccess() {
        return <MessageBar messageBarType={MessageBarType.success}
                           dismissButtonAriaLabel='Close'>
            <p>{this.state.successMessage}</p>
        </MessageBar>;
    };

    renderMatter() {
        return (
            <div>
                <EmailTitle email={this.props.currentEmail} matter={this.state.emailMatter}/>

                {
                    !this.showingEmailMatter() &&
                        <div style={{padding: '0.25rem'}}>
                            <MatterLink id={this.state.matterId} description={this.state.matter['description']} size={'large'}/>
                        </div>
                }
            </div>
        );
    }

    renderSaveEmailSearch() {
        return <div key={'saveEmailSearch'}>
            <SaveEmailSearch currentEmail={this.props.currentEmail}
                             apiClient={this.props.apiClient}
                             saveCallback={this.saveEmailToMatter}
                             uploadToCallback={this.uploadToCallback}
                             unitTest={this.props.unitTest}
                             asyncCallMade={this.props.asyncCallMade}/>

            {this.props.currentEmail.attachments().length > 0 &&
                <SaveEmailItems currentEmail={this.props.currentEmail}
                                shouldSaveEmailCallback={this.shouldSaveEmail}
                                shouldSaveAttachmentCallback={this.shouldSaveAttachments}/>
            }
        </div>;
    }

    saveAttachments = (matter) => {
        return Promise.all(this.state.saveAttachments.map((nextAttachment) => {
            if (nextAttachment['name'].length > 0) {
                return this.props.currentEmail.saveAttachment(
                    this.props.apiClient,
                    nextAttachment,
                    matter['id'],
                    this.props.username,
                );
            }
            else {
                FylerLogger.info(`Ignoring attachment ${JSON.stringify(nextAttachment)} with no name`);
                return Promise.resolve();
            }
        }));
    };

    saveEmailToMatter = (matter) => {
        if (!!matter) {
            if (!this.state.saveEmail && this.state.saveAttachments.length === 0) {
                this.setState({errorMessage: 'Please select the components of the email you would like to file'});
            }
            else {
                const success = () => {
                    this.setState({ matterId: matter['id'] }, this.loadMatter);
                };
                const failure = (reason) => {
                    this.setState({
                        working: false,
                        matter: null,
                        errorMessage: this.unexpectedError(JSON.stringify(reason))
                    })
                };

                this.setState({
                    errorMessage: null,
                    working: true,
                    matter: matter
                }, () => {
                    if (this.state.saveEmail) {
                        this.props.currentEmail.save(this.props.apiClient, matter['id'], this.props.username,
                            async () => {
                                if (this.state.saveAttachments.length > 0) {
                                    this.saveAttachments(matter)
                                        .then(success)
                                        .catch(failure);
                                }
                                else {
                                    success();
                                }
                            }, failure);
                    }
                    else {
                        this.saveAttachments(matter)
                            .then(success)
                            .catch(failure);
                    }
                });
            }
        }
    };

    uploadToCallback = (matter) => {
        if (!!matter) {
            this.setState({dragAndDrop: true, matter: matter});
        }
    }

    shouldSaveAttachments = (attachmentsToSave, attachmentsToNotSave) => {
        let newAttachments = [...this.state.saveAttachments];

        attachmentsToSave.forEach((nextAttachment) => {
            if (!!!newAttachments.find((existingAttachment) => existingAttachment['id'] === nextAttachment['id'])) {
                newAttachments = [...newAttachments].concat(nextAttachment);
            }
        });
        attachmentsToNotSave.forEach((nextAttachment) => {
            newAttachments = [...newAttachments].filter((existingAttachment) => existingAttachment['id'] !== nextAttachment['id']);
        });

        this.setState({saveAttachments: newAttachments});
    };

    shouldSaveEmail = (shouldSave) => {
        this.setState({saveEmail: shouldSave});
    };

    showingEmailMatter = () => {
        return !!this.state.emailMatter &&
            (this.state.emailMatter['id'] === this.state.matterId);
    };

    unexpectedError = (error) => {
        return `There was an unexpected error performing this operation. 
            Please email the content of this error message, and a description of what action you performed, to support@verlata.com to assist us in diagnosis.
            ${error}`;
    }
}
