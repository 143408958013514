import * as React from 'react';
import { FileDocument } from '@verlata/fyler-office-common';
import gql from 'graphql-tag';
import ComposingMatterSearchResult from "../compose/ComposingMatterSearchResult";
import ReadingMatterSearchResult from "../read/ReadingMatterSearchResult";
import {FylerLogger} from "@verlata/fyler-office-common";
import {MessageBar, MessageBarType} from "office-ui-fabric-react";

export default class SaveEmailSearch extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
    };

    state = {
    };

    render() {
        return <FileDocument key={`filing-${this.props.currentEmail.emailId()}`}
                             apiClient={this.props.apiClient}
                             makeSuggestions={this.makeSuggestions}
                             renderResults={this.renderResults}
                             onSearch={this.userSearched}
                             query={this.props.query}
                             asyncCallMade={this.props.asyncCallMade}/>;
    }

    renderResults = (results) => {
        if (results.length === 0) {
            return <MessageBar messageBarType={MessageBarType.info} dismissButtonAriaLabel='Close'>
                No matching folders found
            </MessageBar>;
        }
        else if (this.props.currentEmail.composing()) {
            return <ComposingMatterSearchResult results={results}
                                                currentEmail={this.props.currentEmail}
                                                saveToMatterCallback={this.props.saveCallback}
                                                unitTest={this.props.unitTest}/>;
        }
        else {
            return <ReadingMatterSearchResult results={results}
                                              moveToMatterCallback={this.props.saveCallback}
                                              uploadToMatterCallback={this.props.uploadToCallback}
                                              unitTest={this.props.unitTest}/>;
        }
    };

    makeSuggestions = async (callback) => {
        FylerLogger.info('Getting folder suggetsions for currently selected email');
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query Suggest($from: AWSEmail, $to: [AWSEmail!], $cc: [AWSEmail!], $subject: String) {
                    suggestFolderForEmail(from: $from, to: $to, cc: $cc, subject: $subject)
                }`),
                variables: {
                    from: await this.props.currentEmail.from(),
                    to: await this.props.currentEmail.to(),
                    cc: await this.props.currentEmail.cc(),
                    subject: await this.props.currentEmail.subject()
                },
                fetchPolicy: 'no-cache'
            }).then((results) => {
                let suggestedFolderIds = results.data['suggestFolderForEmail'];
                if (!!this.props.matter) {
                    suggestedFolderIds = suggestedFolderIds.filter(e => e !== this.props.matter.id)
                    suggestedFolderIds.unshift(this.props.matter.id);
                }
                FylerLogger.info('Got the following folder suggetsions');
                FylerLogger.info(suggestedFolderIds);
                callback(suggestedFolderIds);
            }).catch((reason) => {
                FylerLogger.error(`Failed to load suggestions because: ${reason}`);
                callback([]);
            })
        );
    };

    userSearched = () => {
        this.props.saveCallback(null);
    };
}
