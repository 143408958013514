import {FylerDocument} from "@verlata/fyler-office-common";

export default class DownloadableFylerDocument extends FylerDocument {

    constructor(document, unitTest = false) {
        super(document);
        this.unitTest = unitTest;
    }

    downloadFrom = (downloadUrl) => {
        window.location.href = downloadUrl;
    };

}
