import * as React from 'react';
import ReadHome from "./read/ReadHome";
import {CurrentEmailContext} from "./CurrentEmailContext";
import {ApiClientContext} from "./ApiClientContext";
import ComposeHome from "./compose/ComposeHome";
import {CommandBar} from "office-ui-fabric-react";
import {Diagnostics} from "@verlata/fyler-office-common";

export default class Home extends React.Component {

    state = {
        showDiagnostics: false
    };

    render() {
        if (this.state.showDiagnostics) {
            return <Diagnostics finished={() => this.setState({showDiagnostics: false})}/>;
        }
        else {
            return (
                <div className='root-panel'>
                    <div className={'content-panel'}>
                        <ApiClientContext.Consumer>
                            {apiClient =>
                                <CurrentEmailContext.Consumer>
                                    {currentEmail =>
                                        currentEmail.composing() ?
                                            <ComposeHome apiClient={apiClient}
                                                         username={this.props.username}
                                                         currentEmail={currentEmail}/> :
                                            <ReadHome apiClient={apiClient}
                                                      username={this.props.username}
                                                      currentEmail={currentEmail}
                                                      addFilingIndicator={this.addFilingIndicator}
                                                      removeFilingIndicator={this.removeFilingIndicator}/>
                                    }
                                </CurrentEmailContext.Consumer>
                            }
                        </ApiClientContext.Consumer>
                    </div>

                    {this.renderActions()}
                </div>
            );
        }
    }

    renderActions() {
        return <CommandBar
            className={'actions'}
            items={[]}
            farItems={[
                {
                    key: 'diagnostics',
                    name: 'View diagnostic information about Fyler',
                    iconProps: {
                        iconName: 'Diagnostic'
                    },
                    iconOnly: true,
                    onClick: () => this.setState({showDiagnostics: true})
                },
                {
                    key: 'signOut',
                    name: 'Sign Out',
                    iconProps: {
                        iconName: 'SignOut'
                    },
                    iconOnly: true,
                    onClick: () => this.props.signOut()
                },
            ]}
        />;
    }

    addFilingIndicator = () => {
        Office.context.mailbox.item.categories.addAsync(this.filingIndicator(), (asyncResult) => this.logIndicatorResult(asyncResult, "categories.addAsync"));
    }

    removeFilingIndicator = () => {
        Office.context.mailbox.item.categories.removeAsync(this.filingIndicator(), (asyncResult) =>  this.logIndicatorResult(asyncResult, "categories.removeAsync"));
    }

    logIndicatorResult = (asyncResult, call) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            console.log("Successfully removed category");
        } else {
            console.log(call + " call failed with error: " + asyncResult.error.message);
        }
    }

    filingIndicator = () => {
        return ["Saved in Fyler"];
    }
}
