/* global Office */

import 'isomorphic-fetch';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import App from './components/App';
import './styles.css';
import 'office-ui-fabric-react/dist/css/fabric.min.css';

Office.initialize = () => {};

initializeIcons();

let isOfficeInitialized = false;

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <App title={'Fyler'} isOfficeInitialized={isOfficeInitialized} />
        </AppContainer>,
        document.getElementById('container')
    );
};

Office.onReady()
    .then(() => {
        isOfficeInitialized = true;
        render();
    });

render();
