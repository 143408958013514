import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync/lib';
import {Auth} from "aws-amplify";
import {InstrumentedAppSyncClient, ApiClientConfig} from "@verlata/fyler-office-common";
import { InMemoryCache } from 'apollo-cache-inmemory';

export default class AppSyncClientFactory {

    static newClient(region, allowOffline = false) {
        return new InstrumentedAppSyncClient(
            new AWSAppSyncClient({
                url: ApiClientConfig.endpointForRegion(region),
                region: region,
                auth: {
                    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
                },
                disableOffline: !allowOffline
            }, {
                cache: new InMemoryCache(),
                defaultOptions: {
                    query: {
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'none',
                    },
                }
            })
        );
    }
}
