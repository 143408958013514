import {DocumentDownloader} from "@verlata/fyler-office-common";

export default class FylerOutlookUtils {

    static attach(apiClient, email, document) {
        return new DocumentDownloader(apiClient).download(document, (_, downloadUrl) => {
            email.attach(document['file_name'], downloadUrl);
        });
    }

    static download(apiClient, document) {
        return new DocumentDownloader(apiClient).download(document.asObject(), (_, downloadUrl) => {
            document.downloadFrom(downloadUrl);
        });
    }

}
