import * as React from 'react';
import DownloadableFylerDocument from "../DownloadableFylerDocument";
import DownloadableDocumentLink from "./DownloadableDocumentLink";
import {DocumentSearch} from "@verlata/fyler-office-common";
import AttachableDocumentLink from "./AttachableDocumentLink";

export default class MailDocumentSearch extends React.Component {

    render() {
        let visibleColumns = [
            {
                key: 'file_name',
                name: 'Name',
                fieldName: 'file_name',
                minWidth: 128,
                isResizable: false,
                onRender: (document) => {
                    if (this.props.currentEmail.composing()) {
                        return <AttachableDocumentLink apiClient={this.props.apiClient}
                                                       document={new DownloadableFylerDocument(document, this.props.unitTest)}
                                                       currentEmail={this.props.currentEmail}/>;
                    }
                    else {
                        return <DownloadableDocumentLink apiClient={this.props.apiClient}
                                                         document={new DownloadableFylerDocument(document, this.props.unitTest)}
                                                         currentEmail={this.props.currentEmail}/>;
                    }
                }
            }
        ];

        return <DocumentSearch apiClient={this.props.apiClient}
                               columns={visibleColumns}
                               query={this.props.query}
                               unitTest={this.props.unitTest}
                               asyncCallMade={this.props.asyncCallMade} />;
    }

}
