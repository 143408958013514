import gql from 'graphql-tag';
import axios from 'axios';
import CurrentEmail from "../CurrentEmail";
import {decode} from 'base64-arraybuffer';
import {FylerLogger} from "@verlata/fyler-office-common";
import promiseRetry from "promise-retry";
import {FylerUtils} from '@verlata/fyler-office-common';

export default class CurrentReadEmail extends CurrentEmail {

    constructor(officeItem,
                mailbox,
                axiosInstance = axios) {
        super();

        this.officeItem = officeItem;
        this.mailbox = mailbox;
        this.axios = axiosInstance;
    }

    attachments() {
        return this.officeItem.attachments;
    }

    async cc() {
        return this.officeItem.cc.map((nextCC) => {
            return nextCC.emailAddress;
        });
    }

    composing() {
        return false;
    }

    emailId() {
        return FylerUtils.normaliseMessageId(this.officeItem.internetMessageId);
    }

    equals(other) {
        return this.is(other.emailId());
    }

    async from() {
        return this.officeItem.from.emailAddress;
    }

    is(emailId) {
        return this.emailId() === emailId;
    }

    save = (apiClient, matterId, username, successCallback, failureCallback = (_) => {}) => {
        this.mailbox.getCallbackTokenAsync({isRest: true}, (token) => {
            promiseRetry(async (retry, _) => {
                return this.axios.get(`${this.mailbox.restUrl}/v2.0/me/messages/${this.getItemRestId()}?$select=InternetMessageHeaders`, {
                    headers: {'Authorization': `Bearer ${token.value}`}
                }).catch(retry);
            }).then((result) => {
                let references = [];
                const referencesHeader = this.getInternetHeader(result.data, 'References');
                if (!!referencesHeader) {
                    const referencesMatch = referencesHeader.match(/<[^>]+>/g);
                    if (!!referencesMatch) {
                        references = referencesMatch.map((nextReference) => {
                            return FylerUtils.normaliseMessageId(nextReference);
                        });
                    }
                }

                this.saveEmail(apiClient, token.value, matterId, username, references, successCallback, failureCallback);
            }).catch((reason) => {
                FylerLogger.error(`Failed to save email: ${reason}`);
                failureCallback({
                    operation: 'getEmailHeaders',
                    reason: reason
                });
            });
        });
    };

    saveAttachment = (apiClient, attachment, matterId, username) => {
        return new Promise((resolve, reject) => {
            this.createAttachment(apiClient, matterId, username, attachment['name']).then((createAttachmentResponse) => {
                this.officeItem.getAttachmentContentAsync(attachment['id'], (result) => {
                    const uploadUrl = createAttachmentResponse.data['createAttachment']['upload']['url'];
                    const uploadHeaders = this.arrayToHash(createAttachmentResponse.data['createAttachment']['upload']['headers']);
                    const content = decode(result.value.content);

                    this.uploadContent(uploadUrl, content, attachment['contentType'], uploadHeaders)
                        .then(() => {
                            resolve();
                        }).catch((reason) => {
                            reject(reason);
                        });
                });
            }).catch((reason) => {reject(reason);});
        });
    };

    createAttachment(apiClient, matterId, username, fileName) {
        return apiClient.mutate({
            mutation: gql(`mutation CreateAttachment($folderId: String!, $username: String!, $fileName: String!, $emailId: ID!) {
                        createAttachment(folder_id: $folderId, user_name: $username, file_name: $fileName, email_id: $emailId) {
                            id
                            upload {
                                url
                                headers { name value }
                            }
                        }
                    }`),
            variables: {
                folderId: matterId,
                username: username,
                fileName: fileName,
                emailId: this.emailId()
            }
        });
    }

    async subject() {
        return this.officeItem.subject;
    }

    async to() {
        return this.officeItem.to.map((nextTo) => {
            return nextTo.emailAddress;
        });
    }

    arrayToHash(array) {
        return array.reduce((headers, nextItem) => {
            headers[nextItem.name] = nextItem.value;
            return headers;
        }, {});
    }

    getInternetHeader(restEmail, name) {
        if (!!restEmail['InternetMessageHeaders']) {
            for (let nextHeader of restEmail['InternetMessageHeaders']) {
                if (nextHeader['Name'] === name) {
                    return nextHeader['Value'];
                }
            }
        }
        return null;
    }

    getItemRestId() {
        if (this.mailbox.diagnostics.hostName === 'OutlookIOS') {
            // itemId is already REST-formatted
            return this.officeItem.itemId;
        } else {
            // Convert to an item ID for API v2.0
            return this.mailbox.convertToRestId(this.officeItem.itemId, 'v2.0');
        }
    }

    retryOptions() {
        return {retries: 3, maxTimeout: 30000};
    }

    saveEmail = (apiClient, outlookApiToken, matterId, username, referencedEmails, successCallback, failureCallback) => {
        promiseRetry((retry, number) => {
            return apiClient.mutate({
                    mutation: gql(`mutation CreateEmail($email: EmailInput!) {
                    createEmail(email: $email) {
                        id
                        upload {
                            url
                            headers { name value }
                        }
                    }
                }`),
                variables: {
                    email: {
                        folder_id: matterId,
                        user_name: username,
                        subject: this.officeItem.subject,
                        referenced_email_ids: referencedEmails,
                        email_id: this.emailId(),
                        created_at: Math.floor(this.officeItem.dateTimeCreated.getTime() / 1000),
                        from: this.officeItem.from.emailAddress,
                        to: this.officeItem.to.map((nextTo) => {
                            return nextTo.emailAddress
                        }),
                        cc: this.officeItem.cc.map((nextCc) => {
                            return nextCc.emailAddress
                        })
                    }
                }
            }).catch(retry);
        }, this.retryOptions()).then((results) => {
            let uploadUrl = results.data['createEmail']['upload']['url'];
            let uploadHeaders = this.arrayToHash(results.data['createEmail']['upload']['headers']);

            this.upload(outlookApiToken, uploadUrl, uploadHeaders, successCallback, failureCallback);
        }).catch((reason) => {
            FylerLogger.error(`Failed to create email: ${reason}`);
            failureCallback({
                operation: 'createEmail',
                reason: reason
            });
        });
    };

    upload = (outlookApiToken, url, headers, successCallback, failureCallback) => {
        promiseRetry(async (retry, _) => {
            return this.axios.get(`${this.mailbox.restUrl}/v2.0/me/messages/${this.getItemRestId()}/$value`, {
                headers: {'Authorization': `Bearer ${outlookApiToken}`}
            }).catch(retry);
        }).then((result) => {
            this.uploadContent(url, result.data, 'multipart/mixed', headers)
                .then(successCallback)
                .catch((reason) => {
                    FylerLogger.error(`Failed to upload email: ${reason}`);
                    failureCallback({
                        operation: 'uploadEmail',
                        reason: reason
                    });
                });
        }).catch((reason) => {
            FylerLogger.error(`Failed to get MIME content for email: ${reason}`);
            failureCallback({
                operation: 'getEmailContent',
                reason: reason
            });
        });
    }

    uploadContent = (url, content, contentType, headers) => {
        headers['Content-Type'] = contentType;
        return promiseRetry(async (retry, _) => {
            return this.axios.put(url, content, {headers: headers})
                .catch(retry);
        });
    }
}
