/* global Office */
import gql from 'graphql-tag';
import CurrentEmail from "../CurrentEmail";
import {FylerLogger} from "@verlata/fyler-office-common";
import axios from 'axios';
import {encode} from 'base64-arraybuffer';

export default class CurrentComposeEmail extends CurrentEmail {

    constructor(officeItem = Office.context.mailbox.item,
                mailbox = Office.context.mailbox) {
        super();

        this.createdAt = new Date().getTime();
        this.officeItem = officeItem;
        this.mailbox = mailbox;
    }

    attach(name, url) {
        if (this.isMac()) {
            //addFileAttachmentFromBase64Async does not currently exist on Mac OS
            this.officeItem.addFileAttachmentAsync(url, name, (result) => {
                FylerLogger.info(`Attachment completed with status: ${result.status}, error: ${JSON.stringify(result.error)}, diagnostics: ${JSON.stringify(result.diagnostics)}, value: ${JSON.stringify(result.value)}`);
            });
        }
        else {
            axios.get(url, {responseType: 'arraybuffer'})
                .then((response) => {
                    this.officeItem.addFileAttachmentFromBase64Async(encode(response.data), name, (result) => {
                        FylerLogger.info(`Attachment completed with status: ${result.status}, error: ${JSON.stringify(result.error)}, diagnostics: ${JSON.stringify(result.diagnostics)}, value: ${JSON.stringify(result.value)}`);
                    });
                });
        }
    }

    async cc() {
        return await this.officePromise(this.officeItem.cc.getAsync, (result) => {
            if (!!result) {
                return result.map((nextAddress) => nextAddress.emailAddress);
            }
            else {
                return [];
            }
        });
    }

    composing() {
        return true;
    }

    emailId() {
        return null;
    }

    equals(other) {
        return this === other;
    }

    file(apiClient, completeSignal) {
        this.officeItem.loadCustomPropertiesAsync((result) => {
            if (this.checkResult('loadCustomPropertiesAsync', result, completeSignal)) {
                if (!!result.value.get('matterId')) {
                    FylerLogger.warn(`Filing email to ${result.value.get('matterId')}`);
                    this.officeItem.saveAsync((saveResult) => {
                        if (this.checkResult('saveAsync', saveResult, completeSignal)) {
                            this.mailbox.getCallbackTokenAsync({isRest: true}, (token) => {
                                if (this.checkResult('getCallbackTokenAsync', token, completeSignal)) {
                                    this.officeItem.subject.getAsync((subjectResult) => {
                                        if (this.checkResult('subject', subjectResult, completeSignal)) {
                                            const subject = subjectResult.value;

                                            this.officeItem.to.getAsync(async (toResult) => {
                                                if (this.checkResult('to', toResult, completeSignal)) {
                                                    const to = toResult.value.map((nextTo) => {
                                                        return nextTo.emailAddress;
                                                    });

                                                    FylerLogger.warn(`Filing email to ${JSON.stringify(to)} with subject ${subject} and itemId: ${saveResult.value}`);
                                                    apiClient.mutate({
                                                        mutation: gql(`
                                                            mutation Ingest($exchange_host: AWSURL!, $app_id: String!, $token: String!, $to: [String!]!, $subject: String) {
                                                                ingestExchangeEmail(exchange_host: $exchange_host, app_id: $app_id, token: $token, to: $to, subject: $subject)
                                                            }`
                                                        ),
                                                        variables: {
                                                            exchange_host: this.mailbox.restUrl,
                                                            app_id: this.appId(),
                                                            token: token.value,
                                                            to: to,
                                                            subject: subject
                                                        },
                                                        optimisticResponse: async () => {
                                                            //have found when doing cross-region work (e.g. client in Brisbane but server in Ohio)
                                                            //that there needs to be some artificial lag here to make sure the HTTP request sends before
                                                            //signaling completion :(
                                                            await this.delay(500);

                                                            completeSignal.completed({allowEvent: true});
                                                            FylerLogger.warn(`Signaled completion`);
                                                            return "0";
                                                        }
                                                    }).catch((reason) => {
                                                        FylerLogger.error(`Failed to call ingestExchangeEmail because ${JSON.stringify(reason)}`);
                                                        this.officeItem.notificationMessages.addAsync('SendFailed',
                                                            {type: 'errorMessage', message: `Failed to call ingestExchangeEmail because ${JSON.stringify(reason)}`});
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    });
                } else {
                    FylerLogger.warn(`Email not marked for filing`);
                    completeSignal.completed({allowEvent: true});
                }
            }
        });
    }

    async from() {
        return await this.officePromise(this.officeItem.from.getAsync, (result) => {
            return result['address'];
        });
    }

    is(_) {
        return false;
    }

    save(matterId, username, successCallback = () => {}) {
        this.officeItem.loadCustomPropertiesAsync((result) => {
            result.value.set('matterId', matterId);
            result.value.set('username', username);
            result.value.saveAsync((saveResult) => {
                FylerLogger.info(`Set compose email properties to ${matterId} and ${username} with result ${saveResult.status}`);
                successCallback();
            });
        });
    };

    startedComposingAt() {
        return this.createdAt;
    }

    async subject() {
        return await this.officePromise(this.officeItem.subject.getAsync, (result) => {
            return result;
        });
    }

    async to() {
        return await this.officePromise(this.officeItem.to.getAsync, (result) => {
            if (!!result) {
                return result.map((nextAddress) => nextAddress.emailAddress);
            }
            else {
                return [];
            }
        });
    }

    unsave(successCallback) {
        this.officeItem.loadCustomPropertiesAsync((result) => {
            result.value.remove('matterId');
            result.value.remove('username');
            result.value.saveAsync((saveResult) => {
                FylerLogger.info(`Unsaved email with result ${saveResult.status}`);
                successCallback();
            });
        });
    };

    addInformationalMessage(name, message) {
        if (this.isMac() || this.isUbuntu()) {
            this.officeItem.notificationMessages.addAsync(name, {
                type: 'informationalMessage',
                icon: 'icon16',
                message: message,
                persistent: true
            });
        }
    }

    appId() {
        if (window.location.hostname.includes('localhost')) {
            return '006ec1a2-0bfc-482b-ae5f-baf00e2fdfe4';
        } else {
            return '1374e7a8-3b6a-4147-9e1b-4c6da6b34ce4';
        }
    }

    checkResult(callName, result, completeSignal) {
        if (!!result.error) {
            FylerLogger.error(`Call ${callName} failed because ${JSON.stringify(result.error)}`);
            this.officeItem.notificationMessages.addAsync('SendFailed',
                {
                    type: 'errorMessage',
                    message: `Failed to file at step ${callName} because: ${JSON.stringify(result.error)}. Diagnostics: ${JSON.stringify(result.diagnostics)}`
                });
            completeSignal.completed({allowEvent: false});
            return false;
        }
        else {
            return true;
        }
    }

    async delay(milliseconds) {
        await new Promise(resolve => setTimeout(() => resolve(), milliseconds));
    }

    isMac() {
        return window.navigator.userAgent.includes('Mac OS');
    }

    isUbuntu() {
        return window.navigator.userAgent.includes('Ubuntu');
    }
}
