import * as React from 'react';
import {
    DetailsList,
    SelectionMode,
    Text
} from "office-ui-fabric-react";
import DownloadableDocumentLink from "../documents/DownloadableDocumentLink";
import { FylerUtils } from '@verlata/fyler-office-common';

export default class MatterEmails extends React.Component {

    static defaultProps = {
        unitTest: false
    }

    state = {
    }

    render() {
        if (this.props.emails.length === 0) {
            return <Text className={'none-message'}>None</Text>;
        }

        const conversations = this.nameConversations(this.groupByConversation());
        let items = [];
        let groups = [];
        conversations.sort((conversation0, conversation1) => {
            return conversation1['emails'][0].timestamp() - conversation0['emails'][0].timestamp();
        }).forEach((nextConversation) => {
            const emails = nextConversation['emails'];
            groups.push({
                key: emails[0].emailId(),
                name: nextConversation['name'],
                startIndex: items.length,
                count: emails.length,
                isCollapsed: !!!emails.find((nextEmail) => {
                    return this.props.currentEmail.is(nextEmail.emailId());
                })
            });
            items = items.concat(emails);
        });

        return this.emailList(items, groups);
    }

    emailList(items, groups) {
        return <DetailsList
            items={items}
            groups={groups}
            columns={this.columns()}
            compact={true}
            isHeaderVisible={false}
            groupProps={{
                headerProps: {
                    onRenderTitle: (props) => {
                        return <span className={'conversation-title'} title={props.group.name}>
                            {FylerUtils.abbreviate(props.group.name, 35)} ({props.group.count})
                        </span>;
                    }
                }
            }}
            selectionMode={SelectionMode.none}
            {...(this.props.unitTest && {
                skipViewportMeasures: true,
                onShouldVirtualize: () => {
                    return false;
                }
            })}
        />;
    }

    nameConversations(conversations) {
        const namedConversations = [];
        conversations.forEach((nextConversation) => {
            nextConversation = this.sortEmailsDescending(nextConversation);

            let mostRecentEmail = nextConversation[nextConversation.length - 1];
            namedConversations.push({
                name: mostRecentEmail.getMetadataValue('subject') || mostRecentEmail.fileName(),
                emails: nextConversation
            });
        });
        return namedConversations;
    }

    columns() {
        let columns = [
            {
                key: 'file_name',
                name: 'Email',
                minWidth: 128,
                isResizable: true,
                onRender: (email) => {
                    return (
                        <DownloadableDocumentLink apiClient={this.props.apiClient} document={email} currentEmail={this.props.currentEmail}/>
                    );
                }
            }
        ];

        return columns;
    }

    groupByConversation() {
        const conversations = [];
        const emailIdToConversationIndex = {};

        this.props.emails.sort((email0, email1) => {
            return email0.timestamp() - email1.timestamp();
        }).forEach((nextEmail) => {
            this.getReferencedIds(nextEmail).some((nextReferenceId) => {
                if (emailIdToConversationIndex[nextReferenceId] !== undefined) {
                    conversations[emailIdToConversationIndex[nextReferenceId]].add(nextEmail);
                    emailIdToConversationIndex[nextEmail.emailId()] = emailIdToConversationIndex[nextReferenceId];
                    return true;
                }
                else {
                    return false;
                }
            });

            if (emailIdToConversationIndex[nextEmail.emailId()] === undefined) {
                conversations.push(new Set([nextEmail]));
                emailIdToConversationIndex[nextEmail.emailId()] = conversations.length - 1;
            }
        });

        return conversations.map((nextConversation) => {
            return Array.from(nextConversation.values());
        });
    }

    getReferencedIds(email) {
        return email.getMetadataValue('referenced-email-ids') || [];
    }

    sortEmailsDescending(emails) {
        return [...emails].sort((email0, email1) => {
            return email1.timestamp() - email0.timestamp();
        });
    }

}
